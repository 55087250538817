import React from "react";

import LayoutGeneral from "../components/LayoutGeneral";
import Section from "../components/Section";
import SectionRow from "../components/SectionRow";
import SVGCurve from "../components/SVGCurve";
import SEO from "../components/SEO";
import TextDiv from "../components/TextDiv";
import TextParagraph from "../components/TextParagraph";
import AppointmentDiv from "../components/AppointmentDiv";
import DivWithText from "../components/DivWithText";
import InternalLink from "../components/InternalLink";

function OfficePage() {
    return (
        <LayoutGeneral>
            <SEO
                keywords={[`Agile Psychiatry`, `Brian Nahas MD`, `Office`, `Clinic`, `Austin`, `Cedar Park`, `Georgetown`]}
                title="Office Serving Austin, Cedar Park, Georgetown"
                description="Agile Psychiatry Clinic with Brian Nahas, MD serving Austin, Cedar Park, and Georgetown"
            />

            <Section shade="mid">
                <SectionRow>
                    <TextDiv>
                        <h2 className="text-3xl text-center mb-2">Office Closed</h2>
                        <DivWithText className="border-solid border-ap-dark-bg border-2 rounded-md p-4 mt-4 mb-8 bg-ap-dark-bg text-white">
                            <h4 className="text-2xl mb-4">Overview</h4>
                            <TextParagraph className="mb-2">
                                Dr. Nahas has <InternalLink to="/practice-closure-notice">closed his outpatient clinical practice</InternalLink> at Agile
                                Psychiatry.
                            </TextParagraph>

                        </DivWithText>
                    </TextDiv>
                </SectionRow>
            </Section>
            <SVGCurve direction="right-slope" transition="mid-dark"/>
            <Section shade="dark">
                <SectionRow>
                    <AppointmentDiv/>
                </SectionRow>
            </Section>
            {/*<SVGCurve direction="right-slope" color="text-ap-dark-bg" bgColor="bg-gray-200"/>*/}
        </LayoutGeneral>
    );
}


export default OfficePage;
